.dropdown {
	position: relative;
	/* z-index: 0; */
}

.dropdown-button {
	background-color: #0056b3 ;
	color: #fff;
	border: none;
	padding: 5px 10px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	border-radius: 5px;
	margin: 5px;
	z-index: 0;
}

.dropdown-button:hover {
	background-color: #007bff;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9; /* Dropdown background color */
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 5;
}

.open {
	display: block;
}

label {
	display: block;
	padding: 10px;
	cursor: pointer;
	user-select: none;
}

input[type="checkbox"] {
	margin-right: 10px;
}

input[type="text"] {
	padding: 10px;
	width: 100%;
	border: 1px solid #ccc; /* Input border */
}

/* Style for when hovering over a label */
label:hover {
	background-color: #ddd; /* Hover background color */
}
