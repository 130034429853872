.modal {
	/* align-items: center; */
	width: 80%;
	background-color: white;
	box-shadow: 0 2px 8px rgb(0, 0, 0, 0.8);
	position: fixed;
	top: 20vh;
	left: 5%;
	z-index: 100;
}

.modalHeader {
	padding: 1rem;
	background-color: #0056b3;;
	color: white;
}

.modalHeader h1 {
	margin: 0;
	font-size: 1.25rem;
}

.modalContent {
	padding: 1rem;
}

.modalActions {
	display: flex;
	justify-content: flex-end;
}

.cancelButton {
	background-color: #c50e0e;

	color: #fff;
	border: none;
	padding: 5px 10px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	border-radius: 5px;
	margin: 5px;
	max-width: 100px;
}

.cancelButton:hover {
	background-color: #ff0000;
}

@media (min-width: 800px) {
	.modal {
		width: 50rem;
		left: calc((100% - 50rem) / 2);
	}
}
