.input-container {
	display: flex;
	font-family: "arial";
	padding: 2px;
	align-items: center;
	justify-content: space-between;
	max-width: 450px;
	margin: 5px auto;
}

.input-field {
	max-width: 100px;
	border: 1px solid #ccc;
	/* border: none; */
	outline: none;
	font-size: 16px;
	padding: 5px;
	margin: 5px;
}

.input-data {
	border: 1px solid #ccc;
	/* border: none; */
	outline: none;
	font-size: 16px;
	padding: 5px;
	margin: 5px;
}

.button {
	background-color: #0056b3;
	color: #fff;
	border: none;
	padding: 5px 10px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	border-radius: 5px;
	margin: 5px;
}

.button:hover {
	background-color: #007bff;
	
}
